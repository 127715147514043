<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png" /> -->
    <label class="fz-14 mb-1 d-block" style="font-weight:600">
      {{ 'Description' }}
    </label>
    <ckeditor :editor="editor" v-model="$parent.attributes[model]" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
// @ts-ignore
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { ckeditorConfig } from '@/helpers/editor';

export default {
  props: {
    model: {
      type: String,
      default: 'bodyHtml',
    },
    height: {
      type: Number,
      default: null,
    },
    minHeight: {
      type: Number,
      default: 500,
    },
    callback: {
      type: Function,
      default: html => {},
    },
  },
  data() {
    return {
      html: '',
      editor: Editor,
      editorConfig: ckeditorConfig,
    };
  },
  methods: {},
};
</script>
<style lang="scss">
.tox-button--naked {
  .tox-icon.tox-tbtn__icon-wrap {
    display: none;
  }
}
.tox-editor-header {
  top: 60px !important;
}
.ck-editor__editable_inline {
  min-height: 500px;
}
.ck-editor__editable {
  min-height: 500px;
}
</style>
