import { uploadService } from '@/apis/upload.s';
import axios from 'axios';
import imageHelpers from '@/helpers/image';

function objectId() {
  return hex(Date.now() / 1000) + ' '.repeat(16).replace(/./g, () => hex(Math.random() * 16));
}
function hex(value) {
  return Math.floor(value).toString(16);
}

export function uploadAdapter(loader) {
  return {
    upload: () => {
      return new Promise((resolve, reject) => {
        loader.file.then(file => {
          (async () => {
            try {
              let inputTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg', 'image/webp'];
              let typeIdx = inputTypes.indexOf(file.type);
              let extType = inputTypes[typeIdx];
              let ext = extType.replace('image/', '');
              let type = file.name ? file.name.split('.')[file.name.split('.').length - 1] : ext;
              let id = objectId();
              let urlData = await uploadService.get({ filename: `image-${id}.${type}` });
              console.log('urlData -> ', urlData);
              if (urlData) {
                const resPut = await axios.put(urlData.data.presignedUrl, file, {
                  headers: {
                    'Content-Type': `${file.type}`,
                  },
                });
                console.log('resPut -> ', resPut);
                // let obj = {
                //   alt: file.name,
                //   alt_text: file.name,
                //   src: urlData.data.imagePath,
                //   _id: id,
                //   size: size,
                // };
                // return obj;
                resolve({
                  default: `${imageHelpers.url(urlData.data.imagePath, { width: 1000, height: 1000 })}`,
                });
              }
            } catch (e) {
              console.error(e);
              reject(e);
            }
          })();
        });
      });
    },
  };
}

export function ckuploadPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
    return uploadAdapter(loader);
  };
}

export const ckeditorConfig = {
  extraPlugins: [ckuploadPlugin],
};
